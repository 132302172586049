let vueScrollTo = require('vue-scrollto');

export default {
    name: 'ibitrip',
    data() {
        return {
            target: null,
            menu: false,
            modal: false,
            privacy: false
        }
    },
    components: {
        contact: () => import('@/components/contact'),
        login: () => import('@/components/login'),
        advisersList: () => import('@/components/advisers')
    },
    methods: {
        getMostVisible: function (elements) {
            let element = null,
                viewportHeight = window.innerHeight,
                max = 0;
            for (let el of elements) {
                let visiblePx = this.getVisibleHeight(el, viewportHeight);
                if (visiblePx > max) {
                    max = visiblePx;
                    element = el;
                }
            }

            return element.id;
        },
        getVisibleHeight: function (element, viewportHeight) {
            let rect = element.getBoundingClientRect(),
                height = rect.bottom - rect.top,
                visible = {
                    top: rect.top >= 0 && rect.top < viewportHeight,
                    bottom: rect.bottom > 0 && rect.bottom < viewportHeight
                },
                visiblePx = 0;

            if (visible.top && visible.bottom) {
                // Whole element is visible
                visiblePx = height;
            } else if (visible.top) {
                visiblePx = viewportHeight - rect.top;
            } else if (visible.bottom) {
                visiblePx = rect.bottom;
            } else if (height > viewportHeight && rect.top < 0) {
                let absTop = Math.abs(rect.top);

                if (absTop < height) {
                    // Part of the element is visible
                    visiblePx = height - absTop;
                }
            }

            return visiblePx;
        },
        scrollTo: function(element, time=500) {
            let options = {
                container: 'body',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: false,
                y: true
            };
            vueScrollTo.scrollTo(element, time, options);
            this.menu = false;
        },
        handleScroll: function() {
            let elements = document.getElementsByClassName('container');
            this.focused = this.getMostVisible(elements);
            if(window.location.pathname !== `/${this.focused}`)
                window.history.pushState(null, this.focused, `/${this.focused}`);
        },
    },
    beforeMount() {
        if(window.location.href.includes('privacy')) {
            this.privacy = true
            return false
        }
        let route = document.location.href.split('/');
        if(route[3]) this.target = route[3];
    },
    mounted() {
        this.handleScroll();
        window.onscroll = this.handleScroll;
        if(this.target) this.scrollTo(`#${this.target}`, 1000)
    }
}